import React from 'react'
import { useLocation } from '@reach/router'
import SectionContainer from '../../../components/molecules/SectionContainer'
import NewsLetter from '../../../components/organisms/NewsLetter'
import Layout from '../../layout'
import useContentfulPosts from '../../../hooks/useContentfulPosts'
import Content from './content'
import Loader from '../../../assets/images/blog/loader.gif'
import './styles.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { formatSrcToGatsbyImage } from '../../../helpers/formatSrcToGatsbyImage'
import Seo from '../../../helpers/seo'

const BlogPostTemplate = () => {
  const location = useLocation()
  const { posts: allPosts } = useContentfulPosts()
  const currentPathname = location.pathname.replace('/blog/posts/', '').replace('/', '')
  const currentPost = allPosts.find((post) => post.path === currentPathname)

  const HTMLElements = currentPost?.post_content?.content.map((component) => {
    // text special: bold, hyperlink, underline, italic
    const isTextSpecial =
      component.content.length > 1 && component.nodeType === 'paragraph'
    const isOrderedList = component.nodeType === 'ordered-list'
    const isImage = component.nodeType === 'embedded-asset-block'

    if (isTextSpecial) {
      const valueWithMarks = component.content.map((t) => {
        // HYPERLINK
        const textHasHyperlink = t.nodeType === 'hyperlink'
        if (textHasHyperlink) {
          const url = t.data.uri
          const text = t.content[0].value
          return `<a target='_blank' href='${url}'>${text}</a>`
        }

        // BOLD
        const textHasBold = t.marks && t.marks.length && t.marks[0].type === 'bold'
        if (textHasBold) {
          return `<b>${t.value}</b>`
        } else {
          return t.value
        }
      })

      return {
        componentContent: { value: valueWithMarks.join('') },
        type: component.nodeType,
      }
    }

    if (isOrderedList) {
      const itemsOfList = component.content.map(({ content }) => content[0])
      const textsList = itemsOfList.map(({ content }) => {
        const link = content.find((i) => i.nodeType === 'hyperlink')

        if (link) {
          const url = link.data.uri
          const text = link.content[0].value
          return `<a target='_blank' href='${url}'>${text}</a>`
        }

        return content[0].value
      })
      return { componentContent: { value: textsList }, type: component.nodeType }
    }

    if (isImage) {
      const { file, title, description } = component.data.target.fields
      const value = { url: file.url, caption: description, title }
      return { componentContent: { value }, type: component.nodeType }
    }

    return { componentContent: component.content[0], type: component.nodeType }
  })

  if (!currentPost) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <img
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '200px',
          }}
          src={Loader}
          alt="loader"
        />
      </div>
    )
  }

  return (
    <>
      <Seo title={currentPost?.title} />
      <Layout page="Blog">
        <GatsbyImage
          className="blog-post__banner"
          image={formatSrcToGatsbyImage(currentPost?.icon)}
          alt={currentPost?.title}
        />

        <SectionContainer>
          <Content
            elements={HTMLElements}
            date={currentPost?.date}
            featuredPosts={allPosts}
            title={currentPost?.title}
            authors={currentPost?.authors}
            category={currentPost?.category}
            url={`/blog/posts/${currentPost?.path}`}
          />
        </SectionContainer>
        <NewsLetter />
      </Layout>
    </>
  )
}

export default BlogPostTemplate
